/* You can add global styles to this file, and also import other style files */
$gutter: 1rem;

.layout-breadcrumb > * {
    display: none !important;
}

.text-center {
    text-align: center;
}

.ui-panel {
    margin-bottom: 10px;
}

.form-group {
    padding: 8px 10px;
}

.hidden {
    display: none;
}

.button-height {
    height: 46px;
}

.profit {
    background-color: #d3ffc4 !important;
}

.loss {
    background-color: #ffd1cc !important;
}

.success {
    background-color: rgba(104, 159, 56, 0.68) !important;
}

.danger {
    background-color: #D32F2F !important;
    color: white !important;
}

.warning {
    background-color: rgba(251, 192, 45, 0.68) !important;
}

.info {
    background-color: rgba(33, 150, 243, 0.68) !important;
}
